export const enum Tech {
    HTML5,
    CSS3,
    JS,
    TS,
    SCSS,
    REACT,
    WEBPACK,
    ESLINT,
    PHP,
    VSC,
    ANDROID,

    VK,
    TELEGRAM,
}
